@charset 'UTF-8';

// Breadlist Variables
// --------------------

// 項目同士の余白
$form-field-margin: (
  'sm': 3em,
  'md': 3em,
  'lg': 3em
) !default;
