@charset 'UTF-8';

// Font family
$font-family: 'YakuHanJPs', -apple-system, Robot, 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Noto Sans CJK JP', 'メイリオ', Meiryo, Arial, sans-serif;

// body の text color
$body-text-color: #333 !default;

// body の background-color
$background-body: #fff !default;

// ベースの文字サイズ（指定：px）
$base-font-size: (
  sm: 13,
  md: 14,
  lg: 15
) !default;

// body に設定する letter-spacing（単位は自由）
$body-letter-spacing: (
  sm: 0,
  md: 0,
  lg: 0
) !default;

// ベースのline-height
$base-line-height: (
  sm: 1.4,
  md: 1.6,
  lg: 1.6
) !default;
