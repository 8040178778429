@charset 'UTF-8';

.title-header {
  margin-bottom: 4em;
}

.title-lead {
  text-align: center;

  .upper {
    margin-bottom: 1em;
  }

  .lower {
    text-align: left;
  }
}



// form
// ----
.form-title {
  background: $gray-200;
  font-size: 1.8em;
  margin-bottom: 1.6em;
  padding: .6em;
}

.form-label {
  border-left: 6px solid $first-color;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  padding-left: 1em;
}

.qand {
  color: $second-color;
  display: inline-block;
  font-family: 'Anton', sans-serif;
  font-size: 1.4em;
  margin-right: .6em;
}



// comfirm
// -------
.comfirm-list {
  dt {
    font-weight: normal;
    margin-bottom: 1.4em;
  }
  dd {
    font-weight: bold;
    margin-bottom: 4em;
    padding-left: 1.5em;
  }
}



// 冒頭メッセージ @2021.03.16
.message-container {
  background: $fourth-color;
  border-radius: 8px;
  color: $white-color;
  padding: 1em;
  text-align: center;
  p {
    font-weight: 700;
  }
}

.message-btn {
  background: $white-color;
  border: 2px solid $white-color;
  border-radius: 6px;
  color: $base-color;
  display: inline-block;
  font-weight: 700;
  margin-top: 2em;
  padding: .75em 1.5em;
  transition: background .2s;
  i {
    margin-left: .5em;
  }
  &:hover {
    background: $base-color;
    color: $white-color;
  }
}
