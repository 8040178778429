@charset 'UTF-8';

// HTMLページの初期設定
// ----------------

* {
  box-sizing: border-box;
}

html {
  font-size: calc( 1em * 0.625 ); // 16px -> 10px
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  @if ( $fluid-lg == true ) {
    overflow-x: hidden;
  }
}

body {
  background: $background-body;
  color: $body-text-color;
  font-family: $font-family;
  font-size: map-unit($base-font-size, sm, px);
  line-height: map-unit($base-line-height, sm);
  letter-spacing: map-unit($body-letter-spacing, sm);
  min-width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  //font-feature-settings: 'pkna' 1;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  @include media(md) {
    font-size: map-unit($base-font-size, md, px);
    letter-spacing: map-unit($body-letter-spacing, md);
    line-height: map-unit($base-line-height, md);
  }
  @include media(lg) {
    font-size: map-unit($base-font-size, lg, px);
    letter-spacing: map-unit($body-letter-spacing, lg);
    line-height: map-unit($base-line-height, lg);
  }
  // フォントレンダリング設定
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
// IE11 用 hack
_:-ms-lang(x), body { display: flex; flex-flow: column wrap; }


// image
// -----
img {
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

// hyper link
// ----------
a {
  color: $link-color;
  text-decoration: $link-decoration;
  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

// Horizontal Line
// ---------------
hr {
  border-top: 0;
  border-bottom: $hr-border;
  border-left: 0;
  border-right: 0;
  width: 100%;
  height: 0;
  margin: multi-array( $hr-margin, 'sm', 'top') auto multi-array( $hr-margin, 'sm', 'bottom');
  clear: both;
  @include media(md) {
    margin: multi-array( $hr-margin, 'md', 'top') auto multi-array( $hr-margin, 'md', 'bottom');
  }
  @include media(lg) {
    margin: multi-array( $hr-margin, 'lg', 'top') auto multi-array( $hr-margin, 'lg', 'bottom');
  }
}
