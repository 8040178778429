@charset 'UTF-8';

// Color
// -----

// ベースカラーパターン
$base-color:    #D8213D !default;
$first-color:   #1b4892 !default;
$second-color:  #006a76 !default;
$third-color:   #ff6600 !default;
$fourth-color:  #e0005d !default;
$five-color:    #94259e !default;
// グレーパターン
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
// 汎用カラー
$white-color:  #fff    !default; // 白
$black-color:  #000    !default; // 黒
$red-color:    #b0120a !default; // 赤
$yellow-color: #fff000 !default; // 黄
$orange-color: #f18f2c !default; // 橙
$green-color:  #63a764 !default; // 緑
$blue-color:   #005fcc !default; // 青
$pink-color:   #ff0066 !default; // ピンク
//
// 上記でカラーを追加した場合、下記の配列にも追加する（ '使用する名前': 変数;
//
$theme-colors: (
  'base':          $base-color,
  'first':         $first-color,
  'second':        $second-color,
  'third':         $third-color,
  'fourth':        $fourth-color,
  'five':          $five-color
) !default;

$gray-colors: (
  'gray-100':     $gray-100,
  'gray-200':     $gray-200,
  'gray-300':     $gray-300,
  'gray-400':     $gray-400,
  'gray-500':     $gray-500,
  'gray-600':     $gray-600,
  'gray-700':     $gray-700,
  'gray-800':     $gray-800,
  'gray-900':     $gray-900
) !default;

$common-colors: (
  'white':         $white-color,
  'black':         $black-color,
  'red':           $red-color,
  'yellow':        $yellow-color,
  'orange':        $orange-color,
  'green':         $green-color,
  'blue':          $blue-color,
  'pink':          $pink-color
) !default;
//
$basic-colors: map-merge( $gray-colors, $common-colors ) !default;
//
$color-pattern: map-merge( $theme-colors, $basic-colors ) !default;
