@charset 'UTF-8';

// 確認用モーダルウインドウ
//

.confirm-wrapper {
  background: #fff;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
}


.confirm-container {
  background: #fff;
  margin: 0 auto;
  padding: 2em 1em;
  width: 90%;
  max-width: 768px;
}



h4.confirm-heading {
  color: $base-color;
  margin-bottom: 1.5em;
  text-align: center;
}



ul.confirm {
  margin-bottom: 3.2em;

  > li {
    display: block;
    &:not(:last-child){
      margin-bottom: 2em;
      @include media(md){
        margin-bottom: 3em;
      }
    }
    //@include media(md){
    //  display: flex;
    //  align-items: flex-start;
    //}

    .head {
      background: $gray-100;
      color: #333;
      font-size: 10px;
      font-weight: 700;
      margin-bottom: 1em;
      padding: 8px 10px;
      @include media(md){
        font-size: 12px;
        margin-bottom: 1.5;
        //margin-right: 2em;
        padding: .4em .5em;
        //text-align: right;
        //width: 28%;
      }
    }
    .contents {
      font-weight: 500;
      flex: 1;
      padding-left: 1em;
    }
  }
}



body.overflow {
  overflow: hidden;
}
